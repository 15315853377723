<script lang="ts" setup>
import { checkIsPoiApp } from '@component-library/business-logic/app';
import useLegacyStore from '@component-library/composables/useLegacyStore';
import { App, Item, isItemNonSpatial } from '@component-library/gather';
import { getLayerIconByType } from '@component-library/layers';
import { useToastStore } from '@component-library/store/toasts';
import _debounce from 'lodash/debounce';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import api from '../api';
import Spinner from '@component-library/components/Spinner.vue';

const props = defineProps<{
  templateTabs: App[];
  goToSample: Function;
  editSample: Function;
  openSampleVersionControl: Function;
}>();

const legacyStore = useLegacyStore();
const toastStore = useToastStore();

const loading = ref(false);
const sampleQuery = ref<string | null>(null);
const templateTabId = ref<number | null>(null);
const isLoadFinished = ref(false);
const samples = ref<Item[]>([]);
const currentPage = ref(1);
const samplesTable = ref<HTMLDivElement | null>(null);
const hasMultiplePages = ref(false);

const isOnline = computed(() => legacyStore.state.isOnline);
const appsInFilter = computed<App[]>(() => {
  const apps = props.templateTabs;
  return apps.filter((app) => !checkIsPoiApp(apps, app.id));
});
const filteredSamples = computed<Item[]>(() => {
  return samples.value.filter((s) => {
    const { template_tab_id: appId } = s;
    if (!appId) {
      return true;
    }

    return !checkIsPoiApp(props.templateTabs, appId);
  });
});

const searchSamples = _debounce(function () {
  getSamples(true);
}, 400);

async function getSamples(reset = false) {
  if (reset) {
    samples.value = [];
    isLoadFinished.value = false;
    currentPage.value = 1;
    hasMultiplePages.value = false;
  }

  if (isLoadFinished.value || loading.value) {
    return;
  }

  loading.value = true;

  try {
    const { data } = await api.getPaginatedSamples({
      query: sampleQuery.value,
      page: currentPage.value,
      templateTabIds: templateTabId.value
        ? [templateTabId.value]
        : props.templateTabs.map((t) => t.id),
    });

    if (navigator.onLine) {
      const paginator = data.samples;
      if (paginator.data.length == 0) {
        isLoadFinished.value = true;
      } else {
        currentPage.value = paginator.current_page + 1;
        samples.value = [...samples.value, ...paginator.data];
      }
      if (!hasMultiplePages.value) {
        hasMultiplePages.value = !!paginator.next_page_url;
      }
    } else {
      samples.value = data.samples.data;
      isLoadFinished.value = true;
    }

    loading.value = false;
  } catch (e) {
    loading.value = false;
    toastStore.error('Failed to load samples, refresh and try again.');
    throw e;
  }
}

function onScroll(e) {
  const ele = e.target;
  if (ele.scrollHeight - ele.scrollTop === ele.clientHeight) {
    getSamples();
  }
}

watch(hasMultiplePages, async (newValue) => {
  await nextTick();

  const { clientHeight, scrollHeight } = samplesTable.value!;
  if (newValue && clientHeight === scrollHeight) {
    getSamples();
  }
});

onMounted(getSamples);
</script>

<template>
  <div class="position-relative sample-tab-container">
    <div
      v-if="loading && samples.length == 0 && !sampleQuery && !templateTabId"
      class="d-flex align-items-center justify-content-center"
    >
      <Spinner small />
    </div>
    <template v-else>
      <div class="input-group mb-3">
        <span class="input-group-text">
          <i class="fas fa-search" v-if="!loading"></i>
          <Spinner small v-else />
        </span>

        <input
          type="text"
          class="form-control"
          placeholder="search for an item..."
          v-model="sampleQuery"
          @input="searchSamples"
        />

        <select
          v-if="appsInFilter.length > 1"
          class="form-control"
          v-model="templateTabId"
          @change="getSamples(true)"
        >
          <option :value="null">All apps</option>
          <option v-for="app of appsInFilter" :key="app.id" :value="app.id">
            {{ app.title }}
          </option>
        </select>
      </div>

      <div
        v-if="samples.length == 0 && !loading"
        class="d-flex align-items-center justify-content-center flex-column h-100 text-center py-3"
      >
        <h1 class="fal fa-exclamation-triangle"></h1>
        <h5 class="fw-bolder">No samples exist with these parameters.</h5>
        <p class="text-muted mb-0">
          Try changing the parameters to find your samples.
        </p>
      </div>
      <div
        v-else
        ref="samplesTable"
        class="table-responsive custom-scrollbar"
        @scroll="onScroll"
      >
        <table class="table table-hover">
          <tbody>
            <tr
              v-for="sample in filteredSamples"
              :key="sample.id"
              class="clickable"
            >
              <td class="text-center">
                <i
                  v-if="sample.area_figure_layer"
                  class="fad"
                  :class="
                    getLayerIconByType(
                      sample.area_figure_layer.geojson.properties.type
                    )
                  "
                  :style="{
                    color: sample.area_figure_layer.geojson.properties.color,
                  }"
                />
                <i class="fas fa-table" v-else-if="isItemNonSpatial(sample)" />
                <i class="fas fa-map-marker" v-else />
              </td>
              <td>{{ sample.custom_title || sample.lab_title }}</td>
              <td class="text-end" :style="{ minWidth: '140px' }">
                <button
                  v-if="sample.latitude && sample.longitude"
                  type="button"
                  class="btn btn-sm btn-outline-secondary ms-1"
                  @click="goToSample(sample)"
                >
                  <i class="fas fa-location" />
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-outline-secondary ms-1"
                  @click="editSample(sample)"
                >
                  <i class="fas fa-pencil" />
                </button>
                <button
                  v-if="
                    isOnline &&
                    !sample.offline_user_id &&
                    sample.template_tab_id
                  "
                  type="button"
                  class="btn btn-sm btn-outline-secondary ms-1"
                  @click="openSampleVersionControl(sample)"
                >
                  <i class="fas fa-history" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<style scoped>
.sample-tab-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
</style>

<script setup lang="ts">
withDefaults(
  defineProps<{
    label?: string;
    value?: string;
    placeholder?: string;
    isRequired?: boolean;
  }>(),
  {
    label: 'Title',
    placeholder: 'Type text here...',
    isRequired: true,
  }
);

function handleInput(onInput, target: EventTarget | null) {
  if (typeof onInput === 'function') {
    onInput((target as HTMLInputElement).value.trim());
  }
}
</script>

<template>
  <div>
    <label class="form-label">
      {{ label }}
      <sup v-if="isRequired" class="text-danger">*</sup>
    </label>
    <input
      type="text"
      class="form-control"
      :placeholder="placeholder"
      :value="value"
      @input="handleInput($listeners.input, $event.target)"
    />
  </div>
</template>
